
import { Portal } from '@linusborg/vue-simple-portal'
import { setSelector } from '@linusborg/vue-simple-portal'
setSelector('body')
export default {
  name: 'modal-popup',
  components: {
    Portal,
  },
  props: {
    /**
     * The size of the button
     * @values danger, success
     */
    type: {
      type: String,
    },
  },
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event
      let isEscape
      if ('key' in evt) {
        isEscape = evt.key === 'Escape' || evt.key === 'Esc'
      } else {
        isEscape = evt.keyCode === 27
      }
      if (isEscape) {
        this.$emit('cancel')
      }
    }
  },
}
